export default [
  // {
  //   component: 'CNavItem',
  //   name: 'داشبورد',
  //   to: '/dashboard',
  //   icon: 'cil-speedometer',
  // },
  {
    component: 'CNavTitle',
    name: 'کاربران',
  },
  {
    component: 'CNavItem',
    name: 'لیست کاربران',
    to: '/users/usersList',
    icon: 'cilPeople',
  },
  {
    component: 'CNavTitle',
    name: 'سفارشات',
  },
  {
    component: 'CNavItem',
    name: 'لیست سفارشات',
    to: '/orders/ordersList',
    icon: 'cilBrowser',
  },
  {
    component: 'CNavTitle',
    name: 'تراکنش ها',
  },
  {
    component: 'CNavItem',
    name: 'تراکنش های ریال',
    to: '/transactions/rialTransaction',
    icon: 'cilBrowser',
  },
  {
    component: 'CNavItem',
    name: 'پرداخت ها',
    to: '/transactions/payment',
    icon: 'cilBrowser',
  },
  {
    component: 'CNavTitle',
    name: 'اطلاعات',
  },
  {
    component: 'CNavItem',
    name: 'کارت ها',
    to: '/informations/cards',
    icon: 'cilBrowser',
  },

  {
    component: 'CNavItem',
    name: 'برداشت های ریالی',
    to: '/informations/withdraw',
    icon: 'cilBrowser',
  },
  // {
  //   component: 'CNavTitle',
  //   name: 'نراکنش ها',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'درخواست های برداشت',
  //   to: '/transactions/RequestList',
  //   icon: 'cilWallet',
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Notifications',
  //   to: '/notifications',
  //   icon: 'cil-bell',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Modals',
  //       to: '/notifications/modals',
  //     },
  //   ],
  // },

  // {
  //   component: 'CNavGroup',
  //   name: 'Pages',
  //   to: '/pages',
  //   icon: 'cil-star',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'خروج',
  //       to: '/pages/login',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Register',
  //       to: '/pages/register',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Error 404',
  //       to: '/pages/404',
  //     },
  //   ],
  // },
  {
    component: 'CNavItem',
    name: 'خروج',
    to: '/pages/login',
    icon: 'cilWalk',
  },
]
