import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'خانه',
    component: DefaultLayout,
    redirect: '/users/usersList',
    children: [
      {
        path: '/dashboard',
        name: 'داشبورد',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/users/usersList',
        name: 'لیست کاربران',
        component: () => import('@/views/users/UsersList.vue'),
      },
      {
        path: '/orders/ordersList',
        name: 'لیست سفارشات',
        component: () => import('@/views/orders/OrdersList.vue'),
      },
      {
        path: '/transactions/rialTransaction',
        name: 'تراکنش های ریالی',
        component: () => import('@/views/transactions/RialTransaction.vue'),
      },
      {
        path: '/transactions/payment',
        name: 'پرداخت ها',
        component: () => import('@/views/transactions/Payment.vue'),
      },
      {
        path: '/informations/cards',
        name: 'کارت ها',
        component: () => import('@/views/informations/Cards.vue'),
      },
      {
        path: '/informations/withdraw',
        name: 'برداشت های ریالی',
        component: () => import('@/views/informations/Withdraw.vue'),
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!store.getters.getAccessToken
  if (to.path !== '/pages/login' && !isAuthenticated) {
    next('/pages/login')
  } else {
    next()
  }
})
export default router
