<template>
  <router-view />
</template>

<script>
import axios from 'axios'
import { useRouter } from 'vue-router'
export default {
  data() {
    return {
      hasGetComputedStyleError: false,
    }
  },
  created() {
    this.router = new useRouter()
    this.isEnvironmentDevelopment = process.env.NODE_ENV === 'development'
    axios.interceptors.request.use(
      (config) => {
        // if (this.isEnvironmentDevelopment) {
        //     // console.info("✉️ ", config);
        //     config.baseURL = "http://localhost:8080/";
        // }
        config.baseURL = 'https://api.digiswap.online/'
        config.timeout = 120000
        return config
      },
      (error) => {
        if (this.isEnvironmentDevelopment) {
          console.error('✉️ ', error)
        }
        return Promise.reject(error)
      },
    )
    axios.interceptors.response.use(
      (response) => {
        // if (respons.response.data.responseException) {
        //     toastr.error(result.response.data.responseException.exceptionMessage);
        return response
      },
      (error) => {
        if (error.status == 401) {
          this.router.push({ path: '/login' })
        }
        // Handle response error

        // toastr.error(error.response.data.responseException.exceptionMessage)
        return error.response
      },
    )

    this.api = axios
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.hasGetComputedStyleError = false
    },
  },
}
</script>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
