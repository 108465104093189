import { createStore } from 'vuex'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    accessToken: null,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setAccessToken(state, token) {
      state.accessToken = token
      localStorage.setItem('token', token)
    },
  },
  actions: {
    saveAccessToken({ commit }, token) {
      commit('setAccessToken', token)
    },
  },
  getters: {
    getAccessToken(state) {
      if (state.accessToken) return state.accessToken
      state.accessToken = localStorage.getItem('token')
      return state.accessToken
    },
  },
  modules: {},
})
